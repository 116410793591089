<template>
	<div class="select-type sm:hidden" v-click-outside="close">
		<input type="text" readonly v-model="select" class="serch-input" @click="openMenuList = true" />
		<span class="arrow">
			<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
			</svg>
		</span>
		<ul v-show="openMenuList" class="cursor-pointer list">
			<li class="flex" v-if="anketType || dataMO.ambulator" @click="changeSelect({ name: 'Амбулаторные условия', value: 'ambulator' })">
				<span>Амбулаторные условия</span>
			</li>
			<li class="flex" v-if="anketType || dataMO.stacionar" @click="changeSelect({ name: 'Стационарные условия', value: 'stacionar' })">
				<span>Стационарные условия</span>
			</li>
			<li class="flex" v-if="anketType || dataMO.healthResort" @click="changeSelect({ name: 'Санаторно-курортные организации', value: 'healthResort' })">
				<span>Санаторно-курортные организации</span>
			</li>
			<li class="flex" v-if="anketType || dataMO.psychiatric" @click="changeSelect({ name: 'Психиатрические больницы', value: 'psychiatric' })">
				<span>Психиатрические больницы</span>
			</li>
			<li class="flex" v-if="anketType || dataMO.donor" @click="changeSelect({ name: 'Донорство крови', value: 'donor' })">
				<span>Донорство крови</span>
			</li>
			<li class="flex" v-if="anketType || dataMO.skoraya" @click="changeSelect({ name: 'Скорая помощь', value: 'skoraya' })">
				<span>Скорая помощь</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { getters, actions, mutations } from '@/store/store';
import ClickOutside from 'vue-click-outside';

export default {
	data() {
		return {
			select: '',
			anketType: true,
			openMenuList: false,
			menuOpened: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		...mutations,
		close() {
			this.openMenuList = false;
		},
		changeSelect(item) {
			this.select = item.name;
			this.link(item.value);
		},
		link(route) {
			if (route !== this.$route.params.type) this.$router.push({ name: this.$route.name, params: { type: route } });
			this.changeType(this.$route.params.type);
			this.getAnketaById();
			if (this.$route.name === 'QuestionnaireRegion' || this.$route.name === 'QuestionnaireRegion2') {
				let query = {
					text: '',
					id: this.$route.params.id
				};
				if (query.id.length < 10) {
					let gotRegion = this.getRegionById(query.id);
					gotRegion.then((res) => {
						query.id = res.data.id;
						this.getMOList(query);
					});
				} else {
					this.getMOList(query);
				}
				this.setSearch('');

				// если не Донорство и не Скорая
				if (route !== 'donor' && route !== 'skoraya') {
					this.setMO(false);
				} else {
					this.setMO(true);
				}
			}

			this.$emit('callMethodInParent');
			this.close();
		}
	},
	mounted() {
		switch (this.$route.params.type) {
			case 'stacionar':
				this.select = 'Стационарные условия';
				break;
			case 'ambulator':
			case undefined:
				this.select = 'Амбулаторные условия';
				break;
			case 'healthResort':
				this.select = 'Санаторно-курортные организации';
				break;
			case 'psychiatric':
				this.select = 'Психиатрические больницы';
				break;
			case 'donor':
				this.select = 'Донорство крови';
				break;
			case 'skoraya':
				this.select = 'Скорая помощь';
				break;
			default:
				this.select = '';
		}
		this.anketType = this.$route.name === 'QuestionnaireRegion';
	},
	directives: {
		ClickOutside
	}
};
</script>

<style>
.select-type {
	@apply relative;
	width: 100%;
	margin-bottom: 24px;
}

.select-type .arrow {
	@apply absolute right-5 top-6;
}

.select-type .serch-input {
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	padding: 16px 50px 16px 20px;
	border: 1px solid #a8b1ce;
	border-radius: 10px;
	color: #000;
	width: 100%;
}

.serch-input:focus {
	outline: 0;
}

.serch-input:focus + .arrow {
	transform: rotate(180deg);
}

.select-type .list {
	@apply absolute bg-white shadow-default rounded-b-lg;
	top: 53px;
	width: 100%;
	overflow: auto;
	z-index: 999;
	padding: 20px;
	height: auto;
}

.list li {
	padding-bottom: 10px;
}
</style>

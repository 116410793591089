<template>
	<div class="mb-6 sm:mb-0">
		<div v-if="currentRegion !== null" class="item mb-6">
			<p class="label-lg">Регион</p>
			<p class="value-lg">{{ currentRegion.name }}</p>
		</div>
		<div class="item w-full">
			<p class="label-lg">
				{{
					currentRegion !== null && currentRegion.fedDistrictCode !== null && currentRegion.wikiRegionCode === null
						? 'Медицинская организация, учредителем которой является Российская Федерация'
						: 'Медицинская организация'
				}}
			</p>
			<Autocomplite type="autocomplite" placeholder="Пожалуйста, выберите МО" />
			<div class="item mt-6" v-if="dataMO.departmentBelongName">
				<p class="label-lg">Функции и полномочия учредителя от имени Российской Федерации осуществляет:</p>
				<p class="value-lg">{{ dataMO.departmentBelongName }}</p>
			</div>
			<div class="item mt-6" v-if="dataMO.webSiteAddress">
				<p class="label-lg">Официальный сайт</p>
				<p class="value-lg">{{ dataMO.webSiteAddress }}</p>
			</div>
			<div class="item mt-6" v-if="dataMO.address">
				<p class="label-lg" v-if="dataMO.address">Фактический адрес</p>
				<p class="value-lg" v-if="dataMO.address">{{ dataMO.address }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getters, actions } from '@/store/store';
import Autocomplite from '@/components/Autocomplite.vue';

export default {
	name: 'ChangeRegion',
	components: { Autocomplite },
	data() {
		return {};
	},
	computed: {
		...getters
	},
	methods: {
		...actions
	},
	mounted() {}
};
</script>

<style>
.label-lg {
	@apply text-sm pb-2.5 font-bold;
	color: #a8b1ce;
}

.value-lg {
	@apply font-medium text-base;
}
</style>
